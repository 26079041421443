export const CertificateConstants = {
    STUDENT_ID_REPLACE_PATTERN: "{##}"
};

export function numDaysBetweenDates(startDate, endDate) {
    let millisecondsPerDay = 24 * 60 * 60 * 1000;
    return ((endDate - startDate) / millisecondsPerDay) + 1; // + 1 because the result has to be first day inclusive 
}
  
/**
 * Having 2 dates return an array with the number of day correspond to the dates in between them inclusive.
 * Eg: start: 15-01-2009, end: 19-01-2009, result: [15, 16, 17, 18, 19] 
 */
const dayBetween = (start, end) => {
    let days = [];
    let numDays = numDaysBetweenDates(start, end);
    let currentDay = new Date(start);
    for (let i = 0; i < numDays; i++) {
      let dom = currentDay.getDate()
      days[i] = dom;
      currentDay.setDate(dom + 1);
    }
    return days;
}

export const datesOfTraining = (dateStart, dateEnd) => {
    let startDateMillis = dateStart.getTime();
    let endDateMillis = dateEnd.getTime();
    let daysBetween = dayBetween(dateStart, dateEnd);
    let daysBetweenStr = daysBetween.join(', ');
    const index = daysBetweenStr.lastIndexOf(',');
    daysBetweenStr = daysBetweenStr.substring(0, index) + ' y' + daysBetweenStr.substring(index + 'y'.length);
    let days = numDaysBetweenDates(startDateMillis, endDateMillis);
    if (days > 1) {
      return `Celebrado el día ${daysBetweenStr} de ${dateStart.toLocaleDateString("es-ES", { month: 'long' })} de ${dateStart.toLocaleDateString("es-ES", { year: 'numeric' })}`;
    } else {
      return `Celebrado el día ${dateStart.toLocaleDateString("es-ES", { day: 'numeric' })} de ${dateStart.toLocaleDateString("es-ES", { month: 'long' })} de ${dateStart.toLocaleDateString("es-ES", { year: 'numeric' })}`;
    }
  }

  export function convertToRoman(num) {

    var lookup =
      { M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1 }, roman = '', i;
  
    for (i in lookup) {
      while (num >= lookup[i]) { //while input is BIGGGER than lookup #..1000, 900, 500, etc.
        roman += i; //roman is set to whatever i is (M, CM, D, CD...)
        num -= lookup[i]; //takes away the first num it hits that is less than the input
        //in this case, it found X:10, added X to roman, then took away 10 from input
        //input lowered to 26, X added to roman, repeats and chips away at input number
        //repeats until num gets down to 0. This triggers 'while' loop to stop.    
      }
    }
    return roman;
  }

  export const hoursOfTraining = (hours) => {
    if (hours > 1) {
      return `Con duración de ${hours} hrs.`
    } else {
      return `Con duración de 1 hr.`
    }
  }
  
  export const getValidityString = (years) => {
    if (years == 1) {
      return "VIGENCIA POR 1 AÑO"
    } else {
      let yStr = "";
      switch (years) {
        case 2:
          yStr = "DOS";
          break;
        case 3:
          yStr = "TRES";
          break;
        case 4:
          yStr = "CUATRO";
          break;
        case 5:
          yStr = "CINCO";
          break;
        case 6:
          yStr = "SEIS";
          break;
        case 7:
          yStr = "SIETE";
          break;
        case 8:
          yStr = "OCHO";
          break;
        case 9:
          yStr = "NUEVE";
          break;
      }
      return `VIGENCIA POR ${yStr} AÑOS`
    }
  }
  
  export const isSyllabusTooLong = (syllabus) => {
    return syllabus?.length > 12;
  }
