import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font, Line, Svg } from '@react-pdf/renderer';
import { CertificateConstants, datesOfTraining, isSyllabusTooLong, convertToRoman, signatureFileToString, getValidityString, hoursOfTraining } from '../../../helper/CertificateHelper';
import InstructorService from '../../../services/InstructorService';
import DeputyService from '../../../services/DeputyService';

// Create styles
Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

Font.register({
  family: 'CenturyA1-Regular',
  src: '/fonts/CenturyA/GothicA1-Regular.ttf'
});

Font.register({
  family: 'CenturyA1-Bold',
  src: '/fonts/CenturyA/GothicA1-Bold.ttf'
});

Font.register({
  family: 'Roboto',
  src: '/fonts/Roboto/Roboto-Regular.ttf'
});

Font.register({
  family: 'Roboto-BoldItalic',
  src: '/fonts/Roboto/Roboto-BoldItalic.ttf'
});

Font.register({
  family: 'Arimo',
  src: '/fonts/Arimo/Arimo-Regular.ttf'
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  preview: {
    position: 'absolute',
    margin: '20%',
    marginLeft: '5%',
    marginTop: '30%',
    width: '860px',
    textAlign: 'center',
    top: 10,
    bottom: 0,
    fontSize: 140,
    color: 'red',
    transform: "rotate(-40eg)",
    fontFamily: 'Helvetica-Bold',
  },
  background: {
    position: 'absolute',
    width: '100%',
    top: 0,
    bottom: 0,
    left: '5%',
    right: '10%',

  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald'
  },
  student: {
    position: 'absolute',
    textTransform: 'uppercase',
    fontSize: 22,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'center',
    top: 305,
    left: '15%',
    right: '10%',
  },
  student2: {
    position: 'absolute',
    textTransform: 'uppercase',
    fontSize: 10,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'center',
    top: 315,
    left: '15%',
    right: '10%',
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    textAlign: 'center',
    fontFamily: 'Oswald'
  },
  curso: {
    position: 'absolute',
    textTransform: 'uppercase',
    fontFamily: 'Helvetica-Bold',
    fontSize: 20,
    margin: 12,
    textAlign: 'center',
    top: 375,
    left: '15%',
    right: '10%',
  },
  curso2: {
    position: 'absolute',
    textTransform: 'uppercase',
    fontFamily: 'Helvetica-Bold',
    fontSize: 14,
    margin: 12,
    textAlign: 'center',
    top: 365,
    left: '25%',
    right: '20%',
  },
  curso3: {
    position: 'absolute',
    textTransform: 'uppercase',
    fontFamily: 'Helvetica-Bold',
    fontSize: 12,
    margin: 12,
    textAlign: 'center',
    top: 355,
    left: '25%',
    right: '20%',
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'center',
    fontFamily: 'Times-Roman'
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'center',
    fontFamily: 'Times-Roman'
  },

  instructor: {
    fontSize: 9.5,
    textAlign: 'center',
    fontFamily: 'Times-Roman',
    textTransform: 'uppercase',
  },

  instructorLabel: {
    fontSize: 9.5,
    textAlign: 'center',
    fontFamily: 'Helvetica-Bold',
    textTransform: 'uppercase',
  },

  instructorView: {
    position: 'absolute',
    left: '15%',
    top: 490,
    width: 200,
  },

  responsableForssspaView: {
    position: 'absolute',
    right: '15%',
    top: 490,
    width: 200,
  },

  registroSTPS: {
    fontSize: 9.5,
    textAlign: 'center',
    fontFamily: 'Helvetica-Bold',
    textTransform: 'uppercase',
  },

  registroSTPSLabel: {
    fontSize: 9.5,
    textAlign: 'center',
    fontFamily: 'Helvetica',
    textTransform: 'uppercase',
  },

  registroSTPSView: {
    position: 'absolute',
    left: '15%',
    top: 515,
    bottom: 0,
    width: 200,
  },

  date: {
    position: 'absolute',
    margin: 11,
    fontSize: 11,
    textAlign: 'center',
    fontFamily: 'Roboto',
    letterSpacing: '1',
    top: 405,
    left: '15%',
    right: '10%',
    textTransform: 'uppercase',
  },

  duration: {
    position: 'absolute',
    margin: 11,
    fontSize: 11,
    textAlign: 'center',
    fontFamily: 'Roboto',
    letterSpacing: '1',
    top: 417,
    left: '15%',
    right: '10%',
    textTransform: 'uppercase',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  folio: {
    position: 'absolute',
    fontFamily: 'Arimo',
    fontSize: 10,
    marginBottom: 20,
    textAlign: 'center',
    color: 'black',
    top: 35,
    left: 710,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },

  reversoTopLogoArea: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center'
  },

  Logo40Area: {
    position: 'absolute',
    width: '20px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    right: 73,
    bottom: -10,
  },

  Logo40: {
    width: '160px',
  },

  reversoTopLogo: {
    width: '200px',
  },

  reversoCursoNombre: {
    width: '100%',
    fontSize: 16,
    textAlign: 'center',
    fontFamily: 'CenturyA1-Regular',
    textTransform: 'uppercase',
    letterSpacing: 1,
    marginTop: 10,
  },

  reversoCursoTemario: {
    position: 'absolute',
    width: '100%',
    fontSize: 16,
    textAlign: 'left',
    fontFamily: 'Helvetica',
    textTransform: 'uppercase',
    top: 200,
    left: 50,
  },

  reversoCursoTemario2: {
    position: 'absolute',
    width: '100%',
    fontSize: 16,
    textAlign: 'left',
    fontFamily: 'Helvetica',
    textTransform: 'uppercase',
    top: 200,
    left: 395,
  },

  reversoCursoTemarioLabel: {
    width: '100%',
    fontSize: 16,
    textAlign: 'center',
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginTop: 20,
  },

  reversoCursoTemarioItem: {
    fontFamily: 'CenturyA1-Regular',
    fontSize: 7,
    paddingTop: 8,
    letterSpacing: 1,
  },

  reversoCursoTemarioItemNumber: {
    fontFamily: 'CenturyA1-Regular',
    fontSize: 7,
    paddingTop: 8,
    letterSpacing: 1,
    width: 30,
  },

  reversoLowerView: {
    position: 'absolute',
    bottom: 20,
    right: 20,
  },

  lowerLogo: {
    width: '200px',
    marginBottom: -30,
  },

  signature: {
    position: 'absolute',
    height: '130px',
    top: -15,
    right: 100,
  },

  signatureDirector: {
    position: 'absolute',
    height: '200px',
    top: -123,
    left: 30,
  },

  signatureInstructor: {
    position: 'absolute',
    height: '180px',
    top: -145,
    left: 0,
  },

  reversoSvg: {
    marginTop: 8,
    marginLeft: 14
  },

  reversoLower: {
    marginLeft: 14,
    fontSize: 7,
    marginBottom: 4
  },

  reversoLower2: {
    fontFamily: 'Roboto-BoldItalic',
    marginLeft: 14,
    fontSize: 7,
  }
});

const getStudentName = (student) => {
  const completeName = student.name + ' ' + student.lastname;
  const style = completeName.length < 50 ? styles.student : styles.student2;
  return <Text style={style}>{completeName}</Text>
};

const getCourseName = (courseName) => {
  const style = courseName.length < 50 
    ? styles.curso 
    : courseName.length < 114 ? styles.curso2
    : styles.curso3;
  return <Text style={style}>{courseName}</Text>
};

const makeSyllabus = (syllabusParam) => {
  let syllabus = syllabusParam.slice(0, 15);
  if (isSyllabusTooLong(syllabus)) {
    let column1Start = 0;
    let column1End = syllabus.length / 2 + 1;
    let column2Start = syllabus.length / 2 + 1;
    let column2End = syllabus.length;
    return <>
      <View style={styles.reversoCursoTemario}>
        <View style={{ flexDirection: "column", width: 300 }}>
          {syllabus?.slice(column1Start, column1End).map((item, index) => (
            <View key={item.id} style={{ flexDirection: "row", marginBottom: 4 }}>
              <Text style={{ ...styles.reversoCursoTemarioItemNumber, marginHorizontal: 8 }}>
                {convertToRoman(index + 1) + '. '}
              </Text>
              <Text style={styles.reversoCursoTemarioItem}>{item.name}</Text>
            </View>
          ))}
        </View>
      </View>
      <View style={styles.reversoCursoTemario2}>
        <View style={{ flexDirection: "column", width: 300 }}>
          {syllabus?.slice(column2Start, column2End).map((item, index) => (
            <View key={item.id} style={{ flexDirection: "row", marginBottom: 4 }}>
              <Text style={{ ...styles.reversoCursoTemarioItemNumber, marginHorizontal: 8 }}>
                {convertToRoman(index + column2Start + 1) + '. '}
              </Text>
              {console.log('item',item)}
              <Text style={styles.reversoCursoTemarioItem}>{item.name}</Text>
            </View>
          ))}
        </View>
      </View>
    </>
  }
  return <View style={styles.reversoCursoTemario}>
    <View style={{ flexDirection: "column", width: 400 }}>
      {syllabus?.map((item, index) => (
        <>
        <View key={item.id} style={{ flexDirection: "row", marginBottom: 4 }}>
          <Text style={{ ...styles.reversoCursoTemarioItemNumber, marginHorizontal: 8 }}>
            {convertToRoman(index + 1) + '. '}
          </Text>
          <Text style={styles.reversoCursoTemarioItem}>{item.name}</Text>
        </View>
        <View key={item.id} style={{paddingLeft: 45, textAlign: 'left', flexDirection: "column", marginBottom: 4 }}>
        { item.subItem.length > 0 && item.subItem.map((subitem) => <Text style={styles.reversoCursoTemarioItem}>- {subitem.name}</Text>)}
        </View>
        </>
      ))}
    </View>
  </View>
}

// Create Document Component
const Certificate1 = ({trainingDetail, isPreview, signature, instructorSignature, deputySignature }) => {

  return (
    <Document>
      {
        trainingDetail.students.map((student, index) => (
          <Page orientation="landscape" style={styles.body}>
            { isPreview && <Text  style={styles.preview}>
              PREVIEW
            </Text>}
            { /* anverso */}
            <Image style={styles.background} src="/assets/fossspa-fondo-constancia-no-logo.png" />

            <View style={styles.Logo40Area}>
              <Image style={[styles.Logo40]} src="/assets/forsspa-logo-40.png" />
            </View>
        
            <Text style={styles.folio}>
              {
                trainingDetail.code.replace(
                  CertificateConstants.STUDENT_ID_REPLACE_PATTERN,
                  (index+1).toString()
                )
              }
            </Text>
            { getStudentName(student) } 
            { getCourseName(trainingDetail.course.name) } 
            <Text style={styles.date}>
              {datesOfTraining(trainingDetail.dateStart, trainingDetail.dateEnd)}
            </Text>
            <Text style={styles.duration}>
              {hoursOfTraining(trainingDetail.totalHours)}
            </Text>

            <View style={styles.instructorView}>
              <Text style={styles.instructor}>
                {`${trainingDetail.instructor.name} ${trainingDetail.instructor.lastname}`}
              </Text>
              <Text style={styles.instructorLabel}>
                Instructor
              </Text>
            </View>

            <View style={styles.registroSTPSView}>
              <Text style={styles.registroSTPSLabel}>REGISTRO INSTRUCTOR STPS:</Text>
              <Text style={styles.registroSTPS}>
                {trainingDetail.instructor.instructorSTPS}
              </Text>
              {signature && instructorSignature && <Image style={styles.signatureInstructor} src={instructorSignature} />}
            </View>

            <View style={styles.responsableForssspaView}>
              <Text style={styles.instructor}>
                {`${trainingDetail.deputy.completeName}`}
              </Text>
              <Text style={styles.instructorLabel}>
                {trainingDetail.deputy.position}
              </Text>
              {signature && deputySignature && <Image style={styles.signatureDirector} src={deputySignature} />}
            </View>

            { /* reverso */}
            <View break>
              { isPreview && <Text style={styles.preview}>
                PREVIEW
              </Text> }
              <View style={styles.reversoTopLogoArea}>
                <Image style={styles.reversoTopLogo} src="/assets/forsspa-lower-logo.png" />
              </View>

              <Text style={styles.reversoCursoNombre}>
                {trainingDetail.course?.name}
              </Text>
              <Text style={styles.reversoCursoTemarioLabel}>
                TEMARIO
              </Text>
              {
                makeSyllabus(trainingDetail.course?.syllabus)
              }
            </View>
            <View style={styles.reversoLowerView}>
              { signature && <Image style={styles.lowerLogo} src="/assets/forsspa-lower-logo-bottom.png" /> }
              <Svg style={styles.reversoSvg} height="10" width="200">
                <Line
                  x1="0"
                  y1="0"
                  x2="200"
                  y2="0"
                  strokeWidth={2}
                  stroke="rgb(92,144,201)" />
              </Svg>
              <Text style={styles.reversoLower}>
                CERTIFICADO DE COMPETENCIA LABORAL 058257
              </Text>
              <Text style={styles.reversoLower2}>
                {getValidityString(trainingDetail.validity)}
              </Text>
              { signature && <Image style={styles.signature} src="/assets/signature/firma-prc.png" /> }
            </View>
          </Page>
        ))
      }
    </Document>
  )
};

export default Certificate1;